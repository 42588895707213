body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {  
  display: block;
  min-width: 100%;
  height: 100%;
}

html, body, #root {margin:0;padding:0;height:100%;min-width:100%;}

.panel{
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: rgb(243, 251, 255);
}
#scrollable-canvas-container {
  background-image: radial-gradient(#EEE 20%, transparent 20%),
      radial-gradient(#d5d5d5 5%, transparent 5%);
  background-color: #FDFDFD;
  background-position: 0 0, 20px 20px;
  background-size: 20px 20px;
  font-size: 1.2em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

input,select {
  background-color:#FFF;
  height: 1.5em;  
  line-height: 1.5em;
  font-size: 20px;
  border:1px solid #999
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.projectName:hover{
  text-decoration: underline;
}

.fieldCard {
  width: 92%;
  padding: 3.3% 4%;
  background-color:#FEFEFE;
  border-bottom:2px solid #CCC;
  text-align:left;
  font-size:12px;
  cursor:pointer;
}

.fieldCard:hover{
  background-color:#EFEFEF;
}

.headerButton {
  background-color: #0681d6;
  color: #FFF;
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  font-weight:300;
  border-right:1px solid #0b4f82;
}

.headerButton:hover{
  background-color: #0a66a8;
}
.fieldsTable {
  width: 100%;
  display: block;
}
.fieldsTable tr th{
  padding: 7px;
  background-color: #DDD;
  border:none;  
  font-size:15px;
}

.fieldsTable tr td{
  padding: 3px;
  background-color: #EEE;
  border-bottom:1px solid #CCC;
  text-align:center;
  font-size:15px
}

.fieldsTable tr td input,.fieldsTable tr td select{
  font-size:15px
}

.editorButton {
  display:inline-block;
  padding:10px;
  background-color:#DDD;
  cursor:pointer;  
  border-radius: 5px;
}

.editorButton:hover {  
  background-color:#CCC;  
}

.deprecated {
  text-decoration: line-through;
}

.modalPanel{
  background-color: #FFF;
}

.entityCard{
  -webkit-box-shadow: 1px 1px 12px 1px rgba(0,0,0,0.35); 
  box-shadow: 1px 1px 12px 1px rgba(0,0,0,0.35); 
  position: relative;
  z-index: 2; 
}

.entity-relationship{
  -webkit-box-shadow: 1px 1px 20px 1px rgba(0,0,0,0.10); 
  box-shadow: 1px 1px 20px 1px rgba(0,0,0,0.10); 
}

.inputLabel{
  font-size: 18px;
  padding-right: 5px;
  display: inline-block;
  min-width: 95px;  
  text-align: right;
}

.panel{
  -webkit-box-shadow: -1px 4px 14px 3px rgba(0,0,0,0.54); 
  box-shadow: -1px 4px 14px 3px rgba(0,0,0,0.54);
  border-bottom:1px solid #CCC   
}

.render_identifier{  
  float: right;
  color:#666;
}

.customQuery, .customQueries{
  display: block;
  width: 96%;
  padding: 2%;
}

.customQuery h1, .customQueries h1 {
  font-size: 25px;
  font-weight: bold;
}

.customQuery h2, .customQueries h2 {
  font-size: 20px;
  font-weight: bold;
}

.customQuery h3, .customQueries h3 {
  font-size: 18px;
  font-weight: bold;
}


.customQuery input {
  width: 350px;
}

.customQuery h1, .customQuery h2{
  margin-bottom: 0px;
}

.customQuery p {
  margin-top: 5px;
}

.customQuery section {
  display: block;
  padding: 15px 0px;
  border-bottom: 1px solid #EEE;
}

.customQueries ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.customQueries ul li {
  display: block;
  padding: 10px;  
  background-color: #EFEFEF;
  margin-bottom: 10px;
  font-size: 20px;
  color:#666;
  cursor: pointer;  
  font-weight: 500;
  min-height: 50px;
}

.customQueries ul li:hover{
  background-color: #d5d5d5;
}

.customQueries ul li span{
  margin-top: 15px;
  display: inline-block;
}

.customQuery .join, .customQuery .condition, .comparison  {
  background-color: #EFEFEF;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #CCC;
  margin-bottom: 10px;
}

.comparison {
  background-color: #d5d5d5;
}

.customQuery span{
  width: 150px;
  display: inline-block;
}

.customQuery select, .customQuery input {
  width: 300px;
}

.customQuery select.comparatorSelect {
  width: 100px;
}

.btn {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  background-color: #0a66a8;
  color:#FFF;
  cursor: pointer;
}

.btn:hover{
  background-color: #0b4f82;
}

.btn.remove {
  background-color: #CC0000;
}

.btn.remove:hover{
  background-color: #760606;
}

.btn.remove {
  float: right;
}

.sectionHeader .btn.add {
  float: right;
}

.sectionHeader{
  display: block;
  width: 100%;
  padding-top: 10px;
}

.sectionHeader .title{
  display: inline-block;
}

.sectionHeader .title h2{
  margin-top: 0px;
}

.subconditions .condition {
  background: rgba(0,0,0,0.1); 
}